






























import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import { TableColumn } from '@/models/TableColumn';
import Table from '@/components/Table/Table.vue';
import { DownloadableItem } from '@/models/DownloadableItem'
import FButton from '@/components/Button/FButton.vue'
import DownloadFileButton from '@/components/Button/DownloadFileButton.vue'
import { ApiService } from '@/services/ApiService';
import { ProfileService } from '@/services/ProfileService';
import { BusyService } from '@/services/BusyService';
import { DownloadService} from '@/services/DownloadService';
import { i18n } from '@/main';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';

@Component({
  components: {
    CardWithTitle, 
    Table, 
    FButton,
    DownloadFileButton
  }, 
  metaInfo: () => ({
    title: DynamicTranslationService.agreementsTitle
  })
})
export default class Agreemnts extends Vue {

  downloadService = DownloadService;

  get tableColumns(): TableColumn[] {
    return [
      { key: 'date', type: 'date', label: i18n.t('G.LIT_Date').toString(), headerStyle: 'width:110px'},
      { key: 'title', label: i18n.t('R.LIT_ItemName').toString()},
      { key: 'user', label: i18n.t('G.LIT_User').toString()},
      { key: 'size', label: i18n.t('R.LIT_FileSize').toString()},
      { key: 'download', type: 'custom', label: i18n.t('R.LIT_Download').toString(), headerStyle:'width: 150px'}
    ]
  }

  data: DownloadableItem[] | null = null;

  async mounted() { 
    if (!ProfileService.profile){ 
      throw new Error('Profile not loaded');
    }

    BusyService.showBusy();
    this.data = await ApiService.post('/api/GetAvailableAgreements', { 
      organizationId: ProfileService.profile.org.id
    })
    BusyService.hideBusy();
  }
  
}
