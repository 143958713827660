























import { DownloadableItem } from '@/models/DownloadableItem'
import { Component, Prop, Vue } from 'vue-property-decorator'
import FButton from '@/components/Button/FButton.vue'
import { DownloadService } from '@/services/DownloadService';
import { PackageStatusDef } from '@/configs/PackageStatusDef';

@Component({ 
  components:{ 
    FButton
  }
})
export default class DownloadFileButton extends Vue {
  @Prop()
  item!: DownloadableItem;

  get ready(){ 
    return ([PackageStatusDef.Ready, PackageStatusDef.Downloaded].includes(this.item.packageStatus)
      || !this.item.isPackage)
      && this.item.path
      && this.item.pathObscured
  }

  get processing() { 
    return ([
      PackageStatusDef.Pending, 
      PackageStatusDef.Requested, 
      PackageStatusDef.Creating, 
      PackageStatusDef.Created
    ].includes(this.item.packageStatus))
  }

  getDownload(){ 
    DownloadService.getDownload(this.item.pathObscured, this.item.title, this.item.id)
  }
}
